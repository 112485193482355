import React from 'react'

const Card = (props) => {
  const { item } = props
  return (
    <div key={item.id} className="relative">
      <dt>
        <div
          className="absolute flex items-center justify-center h-12 w-12 rounded-md text-white"
          style={{ backgroundColor: item.iconbgColor }}
        >
          <img
            src={require(`../../Assets/icons/${item.icon}.svg`).default}
            className="h-6 w-6"
            alt={item.name}
          />
        </div>
        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
          {item.name}
        </p>
      </dt>
      <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
    </div>
  )
}

export default Card
