import React, { useState } from 'react'
import Wrapper from '../Wrapper'
import { Player } from '@lottiefiles/react-lottie-player'
import './Lottie.css'

const Lottie = ({ lottie }) => {
  const [lottieData, setLottieData] = useState(null)
  return (
    <Wrapper data={lottie}>
      {
        lottie.jsonLink && (
          <div className={lottieData ? '' : 'hidden'}>
            <Player
              lottieRef={(instance) => {
                setLottieData(instance)
              }}
              autoplay
              loop
              src={lottie.jsonLink}
              style={{ height: '300px', width: '300px' }}
            />
          </div>
        )
      }
      <div className={lottieData ? 'hidden' : ''}>
        <img
          className="display-initial"
          src={
            require(`../../Assets/images/${lottie.image.url}.${lottie.image.type}`)
              .default
          }
          alt={lottie.image.alt}
        />
      </div>
    </Wrapper>
  )
}

export default Lottie
