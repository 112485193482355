import React from 'react'

const HeadingMain = (props) => {
  return (
    <div className="relative">
      <h2
        className={
          props.theme === 'dark'
            ? 'text-center text-3xl leading-8 font-bold tracking-tight text-white md:text-4xl'
            : 'text-center text-3xl leading-8 font-bold tracking-tight text-gray-900 md:text-4xl'
        }
      >
        {props.header}
      </h2>
      <p
        className={
          props.theme === 'dark'
            ? 'mt-4 max-w-3xl mx-auto text-center text-lg text-gray-100 font-semibold'
            : 'mt-4 max-w-3xl mx-auto text-center text-lg text-gray-500 font-semibold'
        }
      >
        {props.details}
      </p>
      {props.link && (
        <p className="mt-4">
          <a
            href={props.link?.url}
            className={
              props.link.type
                ? 'inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700'
                : 'text-base font-bold text-primary-600 hover:text-primary-500'
            }
          >
            {props.link?.name}
          </a>
        </p>
      )}
    </div>
  )
}

export default HeadingMain
