import React from 'react'

const CardLink = (props) => {
  const { link } = props
  return (
    <dl className="mt-10 space-y-10">
      <a
        href={link.url}
        className="text-base font-bold text-primary-600 hover:text-primary-500"
      >
        {link.name}
      </a>
    </dl>
  )
}

export default CardLink
