import React from 'react'
import HeadingMain from 'components/HeadingMain'

const Cta = ({ data }) => {
  return (
    <div className="relative bg-white lg:max-w-7xl mx-auto">
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={
            require(`../../Assets/images/${data.image.url}.${data.image.type}`)
              .default
          }
          alt={data.image.alt}
        />
      </div>
      <div className="relative text-center max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <HeadingMain
          header={data.header}
          details={data.details}
          theme={data.theme}
          link={data.link}
        />
      </div>
    </div>
  )
}

export default Cta
