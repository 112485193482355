import React from 'react'
import Layout from 'container/Layout'
import Hero from 'container/Hero'
import Feature from 'container/Feature'
import Lottie from 'container/Lottie'
import Feature2 from 'container/Feature2'
import Draggables from 'container/Draggables'
import Terminal from 'container/Terminal'
import Cta from 'container/Cta'

const App = () => {
  const hero = require('data/hero.json')
  const section = require('data/section.json')
  const common = require('data/common.json')
  const editor = require('data/editor.json')
  const marketing = require('data/marketing.json')
  const lottie = require('data/lottie.json')
  const terminal = require('data/terminal.json')
  const cta = require('data/cta.json')

  return (
    <Layout common={common}>
      <Hero hero={hero} />
      <Feature data={section} />
      <Lottie lottie={lottie} />
      <Feature2 data={editor} />
      <Draggables marketing={marketing} />
      <Terminal terminal={terminal} />
      <Cta data={cta} />
    </Layout>
  )
}

export default App
