import React from 'react'

const HeadingSub = (props) => {
  return (
    <>
      <span
        className={
          props.theme === 'dark'
            ? 'block text-sm font-semibold uppercase tracking-wide text-gray-400 sm:text-base lg:text-sm xl:text-base'
            : 'block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base'
        }
      >
        {props.content.lable}
      </span>
      <h3
        className={
          props.theme === 'dark'
            ? 'mt-3 text-2xl font-bold text-white tracking-tight sm:text-3xl'
            : 'mt-3 text-2xl font-bold text-gray-900 tracking-tight sm:text-3xl'
        }
      >
        {props.content.heading}
      </h3>
      <p
        className={
          props.theme === 'dark'
            ? 'mt-3 text-base text-white'
            : 'mt-3 text-base text-gray-500'
        }
      >
        {props.content.details}
      </p>
    </>
  )
}

export default HeadingSub
