import React from 'react'
import emailOpened from 'Assets/images/emailopened.webp'
import HeadingMain from 'components/HeadingMain'
import HeadingSub from 'components/HeadingSub'
import CardList from 'components/CardList'
import CardLink from 'components/CardLink'

export default function Feature({ data }) {
  return (
    <div className="py-16 bg-white overflow-hidden lg:py-24">
      <div className="relative max-w-xl sm:max-w-3xl lg:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <HeadingMain header={data.header} details={data.details} />

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-16 lg:items-center">
            <div className="lg:col-start-2">
              <HeadingSub content={data.content} theme={data.theme} />
              <CardList list={data.content.sectionFeatures} />
              <CardLink link={data.content.link} />
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:mx-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <img
                className="relative mx-auto shadow-ct-md rounded-lg"
                src={emailOpened}
                alt="Email Opened Image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
