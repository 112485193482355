import React from 'react'
import Card from './Card'

const CardList = (props) => {
  const { list } = props

  const datalist = []
  list.map((item, key) => {
    datalist.push({
      id: key + 1,
      name: item.name,
      description: item.description,
      icon: item.icon[0],
      iconbgColor: item.icon[1],
    })
  })

  return (
    <dl className="mt-10 space-y-10">
      {datalist.map((item) => (
        <Card item={item} key={item.id} />
      ))}
    </dl>
  )
}

export default CardList
