import React from 'react'
import CardLink from 'components/CardLink'
import HeadingSub from 'components/HeadingSub'
import terminalImage from 'Assets/images/terminal.webp'

const Terminal = ({ terminal }) => {
  return (
    <div
      className={
        terminal.theme === 'dark'
          ? 'bg-dark overflow-hidden'
          : 'bg-white overflow-hidden'
      }
    >
      <div className="relative max-w-xl sm:max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-0 lg:items-center">
            <div className="lg:col-start-2 lg:pl-28">
              <HeadingSub content={terminal.content} theme={terminal.theme} />
              <CardLink link={terminal.content.link} theme={terminal.theme} />
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <img
                className="relative mx-auto shadow-ct-md rounded-lg"
                src={terminalImage}
                alt="Terminal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terminal
