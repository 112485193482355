import React from 'react'
import bringemail from 'Assets/images/bringemail.webp'
import Wrapper from '../Wrapper'
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider'
import './Draggables.css'

const Draggables = ({ marketing }) => {
  return (
    <Wrapper data={marketing}>
      <img
        className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
        src={bringemail}
        alt=""
      />
      <div
        className="wrap"
        style={{
          cursor:
            'url(' + require('../../Assets/icons/DragIcon.svg').default + ')',
        }}
      >
        <ReactCompareSlider
          style={{ width: '43.5%', overflow: 'initial' }}
          itemOne={
            <ReactCompareSliderImage
              src={require('../../Assets/images/image1.webp').default}
              alt="Image one"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={require('../../Assets/images/image2.webp').default}
              alt="Image two"
            />
          }
          position={70}
          handle={
            <div className="dragHandle">
              <img
                src={require('../../Assets/icons/DragIcon.svg').default}
                className="dragImage"
              />
            </div>
          }
        />
      </div>
    </Wrapper>
  )
}

export default Draggables
