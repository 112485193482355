import React from 'react'
import HeadingMain from 'components/HeadingMain'

const Wrapper = (props) => {
  const { data } = props
  return (
    <>
      <div
        className={
          data.theme === 'dark'
            ? 'relative bg-dark pt-16 overflow-hidden lg:pt-24'
            : 'relative bg-white pt-16 overflow-hidden lg:pt-24'
        }
      >
        <div className="mx-auto px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl xl:max-w-9xl">
          <div>
            <HeadingMain
              header={data.header}
              details={data.details}
              theme={data.theme}
              link={data.link}
            />
          </div>
          <div className="my-12 -mx-4">{props.children}</div>
        </div>
      </div>
    </>
  )
}

export default Wrapper
