import React from 'react'

import editorImg from 'Assets/images/editor.webp'
import CardLink from 'components/CardLink'
import CardList from 'components/CardList'
import HeadingSub from 'components/HeadingSub'

const Feature2 = (props) => {
  const { data } = props
  return (
    <>
      <div className="relative bg-white py-16 mx-auto overflow-hidden">
        {data.isRight ? (
          <div className="relative mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl xl:max-w-9xl">
            <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="max-w-xl sm:max-w-3xl lg:max-w-none mx-auto lg:py-12 lg:mx-0 lg:px-0">
                <div>
                  <div className="relative">
                    <HeadingSub content={data} theme={data.theme} />
                    <CardList list={data.sectionFeatures} />
                    <CardLink link={data.link} />
                  </div>
                  <div
                    className="mt-10 -mx-4 relative lg:mt-0 lg:hidden"
                    aria-hidden="true"
                  >
                    <img
                      className="relative mx-auto rounded-lg"
                      src={editorImg}
                      alt="Inbox user interface"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-12 hidden lg:block lg:mt-0 lg:pb-12 lg:pt-4">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={editorImg}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div className="relative">
                    <HeadingSub content={data} theme={data.theme} />
                    <CardList list={data.sectionFeatures} />
                    <CardLink link={data.link} />
                  </div>
                  <div
                    className="mt-10 -mx-4 relative lg:mt-0 lg:hidden"
                    aria-hidden="true"
                  >
                    <img
                      className="relative mx-auto rounded-lg"
                      src={editorImg}
                      alt="Inbox user interface"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-12 hidden lg:block lg:my-0 lg:py-24 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="http://localhost:8000/static/editor-5bdc0d68bf247b4288cbe27d1ac0fbbf.webp"
                    alt="Customer profile user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Feature2
